

.donate {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.container {
  margin-top: 10%;
  max-width: 800px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

/* Appeals section */
.appeals {
  text-align: center;
  margin-bottom: 40px;
}

.appeals h2 {
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
}

.appeals p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* Item details */
.item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.item img {
  max-width: 150px;
  border-radius: 8px;
  margin-right: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.item-details p {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.item-details p:first-child {
  font-weight: bold;
  margin-bottom: 10px;
}

/* Checkout form */
.checkout-form {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.checkout-form form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.checkout-form label {
  font-weight: bold;
  color: #333;
}

.checkout-form input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

.checkout-form input:focus {
  outline: none;
  border-color: #FF0080;
}

/* Amount increments */
.amount-increments {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.amount-increments button {
  flex: 1 0 calc(25% - 7.5px);
  background-color: #FF0080;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.amount-increments button:hover {
  background-color: #e6007a;
}

/* PayStack Button */
.paystack-button {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.paystack-button:hover {
  background-color: #45a049;
}

/* Thank You Overlay */
.thank-you-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.thank-you-content {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.thank-you-content h2 {
  margin-top: 0;
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.thank-you-content p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 30px;
}

.thank-you-content button {
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.thank-you-content button:hover {
  background-color: #45a049;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
  .container {
    margin-top: 18%;
    padding: 20px;
  }

  .appeals h2 {
    font-size: 22px;
  }

  .appeals p {
    font-size: 14px;
    text-align: left;

  }

  .item {
    flex-direction: column;
    align-items: flex-start;
  }

  .item img {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .checkout-form form {
    grid-template-columns: 1fr;
  }

  .amount-increments button {
    flex: 1 0 calc(50% - 5px);
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 15px;
  }

  .appeals h2 {
    font-size: 24px;
  }

  .appeals p {
    font-size: 12px;
  }

  .item-details p {
    font-size: 16px;
  }

  .checkout-form input {
    font-size: 14px;
  }

  .amount-increments button {
    font-size: 12px;
    padding: 8px 12px;
  }

  .paystack-button {
    font-size: 14px;
    padding: 12px 20px;
  }

  .thank-you-content {
    padding: 20px;
  }

  .thank-you-content h2 {
    font-size: 24px;
  }

  .thank-you-content p {
    font-size: 14px;
  }

  .thank-you-content button {
    font-size: 14px;
    padding: 10px 20px;
  }
}