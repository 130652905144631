
.main-logs{
  overflow-x: hidden; /* Ensure no horizontal scroll on the root element */
}
#fs-navigation {
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 1rem;
  background-color: transparent; /* Transparent background */
  position: fixed;
  z-index: 10000;
}

#fs-navigation .fs-container {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#fs-navigation .fs-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#fs-navigation .fs-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

#fs-navigation .fs-li {
  padding: 0.5rem;
  flex-grow: 1;
  text-align: center;
}

#fs-navigation .fs-li-link {
  font-size: 1.5rem; /* Larger icon size */
  line-height: 1.5em;
  text-decoration: none;
  color: var(--bodyTextColor);
  display: block;
  position: relative;
}

#fs-navigation .fs-li-link:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #FF0080;
  opacity: 1;
  display: block;
  position: absolute;
  bottom: -0.125rem;
  left: 0;
  transition: width 0.3s;
}

#fs-navigation .fs-li-link:hover:before,
#fs-navigation .fs-li-link.fs-active:before {
  width: 100%;
}

#fs-navigation .search-bar {
  width: 100%;
  margin-top: 0.2rem;
  position: relative;
}

#fs-navigation .search-bar input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
}

#fs-navigation .search-icon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.25rem;
  color: #888;
}

#fs-navigation .suggestions-container {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
}

#fs-navigation .suggestion-option {
  padding: 0.75rem;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

#fs-navigation .suggestion-option:last-child {
  border-bottom: none;
}

#fs-navigation .suggestion-option:hover {
  background-color: #f0f0f0;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  #fs-navigation .fs-li-link {
    font-size: 1.25rem;
  }

  #fs-navigation .search-bar input {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 576px) {
  #fs-navigation .fs-li-link {
    font-size: 1rem;
  }

  .card-carousels{
    margin-top: 15%;
  }
  /* .react-multi-carousel-item{
  
  }
   */

  
  #fs-navigation{
    margin-top: -25%;
  }

  #fs-navigation .search-bar input {
    font-size: 0.8rem;
  }
}

.card-carousels{
  padding-top: 8%;
  padding-left: 5%;
  padding-right: 5%;
}

