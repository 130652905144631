.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(100%);

  transition: all 1s;
}
.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}
.fade-in {
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes giggle {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(4deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  40% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-1deg);
  }
  60% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.giggle {
  animation: giggle 2s ease-in-out;
  animation-fill-mode: forwards;
}

.scale-in {
  animation: scaleIn 0.5s ease-in-out forwards;
}

@keyframes scaleIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

.scale-out {
  animation: scaleOut 0.5s ease-in-out forwards;
}

@keyframes scaleOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
}

.slide-in-right {
  animation: slideInRight 0.5s ease-in-out forwards;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.slide-in-left {
  animation: slideInLeft 0.5s ease-in-out forwards;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.flip-in {
  animation: flipIn 0.5s ease-in-out forwards;
}

@keyframes flipIn {
  from {
    transform: rotateX(90deg);
  }
  to {
    transform: rotateX(0deg);
  }
}

.flip-out {
  animation: flipOut 0.5s ease-in-out forwards;
}

@keyframes flipOut {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(90deg);
  }
}

.blur-in {
  animation: blurIn 0.5s ease-in-out forwards;
}

@keyframes blurIn {
  from {
    filter: blur(10px);
  }
  to {
    filter: blur(0);
  }
}

.blur-out {
  animation: blurOut 0.5s ease-in-out forwards;
}

@keyframes blurOut {
  from {
    filter: blur(0);
  }
  to {
    filter: blur(10px);
  }
}
.cs-li:nth-child(2) {
  transition-delay: 200ms;
}
.cs-li:nth-child(3) {
  transition-delay: 400ms;
}
.cs-li:nth-child(5) {
  transition-delay: 200ms;
}
.cs-li:nth-child(6) {
  transition-delay: 400ms;
}
.cs-li:nth-child(8) {
  transition-delay: 200ms;
}
.cs-li:nth-child(9) {
  transition-delay: 400ms;
}
.cs-li:nth-child(11) {
  transition-delay: 200ms;
}
.cs-li:nth-child(12) {
  transition-delay: 400ms;
}
.cs-li:nth-child(15) {
  transition-delay: 200ms;
}
.cs-li:nth-child(16) {
  transition-delay: 400ms;
}
.cs-li:nth-child(18) {
  transition-delay: 200ms;
}
.cs-li:nth-child(19) {
  transition-delay: 400ms;
}

.cs-item:nth-child(1).slideIn {
  animation-delay: 0s;
}

.cs-item:nth-child(2).slideIn {
  animation-delay: 0.2s;
}

.cs-item:nth-child(3).slideIn {
  animation-delay: 0.4s;
}

.cs-item:nth-child(4).slideIn {
  animation-delay: 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .hidden,
  .show {
    transition: none !important; /* Disable transitions for reduced motion */
    transform: none !important; /* Disable transform properties */
  }
}

.login-container {
  width: 50%;
  margin: 5rem auto;
  padding: 2rem;
  border-radius: 1.5rem;
  background-color: #f0f2f5;
}

@media (max-width: 768px) {
  .login-container {
    width: 80%;
    margin: 2rem auto;
    padding: 1rem;
  }
}

/*-- -------------------------- -->
<---           Hero             -->
<--- -------------------------- -*/

#hero-219 {
  /* Centers button */
  text-align: left;
  margin-top: -7%;
  /* 144px - 300px - leaving extra space for the navigation */
  /* 130px - 200px */
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 0rem) {
  #hero-219 {
    /* Centers button */
    text-align: left;
    /* 144px - 300px - leaving extra space for the navigation */
    padding: clamp(9rem, 25.95vw, 18.75rem) 1rem 0;
    /* 130px - 200px */
    padding-bottom: clamp(8.125rem, 12.5vw, 25rem);
    position: relative;
    z-index: 1;
  }

  #hero-219 .cs-background {
    width: 100%;
    height: 80%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }
  #hero-219 .cs-background:before {
    /* Overlay */
    content: "";
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
    /* prevents the cursor from interacting with it */
    pointer-events: none;
  }
  #hero-219 .cs-background img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  #hero-219 .cs-container {
    width: 100%;
    max-width: 80rem;
    margin: auto;
  }
/* Base styles (mobile first) */
#hero-219 .cs-title {
  font-size: 1.4375rem;
  font-weight: 700;
  line-height: 1.2em;
  text-align: left;
  max-width: 100%;
  margin: 0 auto 1rem 0;
  color: #fff;
  position: relative;
}

#hero-219 .cs-button-solid {
  font-size: 1rem;
  line-height: 3.5rem;
  text-decoration: none;
  font-weight: 700;
  margin: auto;
  color: #fff;
  padding: 0 1.5rem;
  background-color: #FF0080;
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
  z-index: 1;
}


@media only screen and (max-width: 29.9375rem) {
  #hero-219 .cs-title {
    font-size: 1.75rem;
    max-width: 100%;
    margin: 0 auto 1rem 0;
  }

  .get-started-button {
    line-height: 3rem;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 0.9rem;
    border: none;
    margin-bottom: 3rem;
  }
}

/* Large mobile devices (480px to 767px) */
@media only screen and (min-width: 30rem) and (max-width: 47.9375rem) {
  #hero-219 .cs-title {
    font-size: 1.875rem;
    max-width: 100%;
    margin: 0 auto 1.125rem 0;
  }

  .get-started-button {
    line-height: 3.25rem;
    width: 50%;
    border: none;
    font-size: 0.95rem;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 4rem;
  }
}

/* Small tablets (768px to 1023px) */
@media only screen and (min-width: 48rem) {
  #hero-219 .cs-title {
    font-size: 2rem;
    max-width: 40rem;
    margin: 0 auto 1.25rem 0;
  }

   .get-started-button {
    line-height: 3.5rem;
    text-decoration: none;
    font-weight: 700;
    color: hsl(0, 0%, 100%);
    background-color: #FF0080;
    border-radius: 0.25rem;
    width: 30%;
    border: none;
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    position: relative;
    z-index: 1;
    font-size: 0.8rem;
    margin-bottom: 5rem;
  }
}

/* Small desktops (1024px to 1279px) */
@media only screen and (min-width: 64rem) {
  #hero-219 .cs-title {
    font-size: 3rem;
    max-width: 45rem;
    margin: 0 auto 1.375rem 0;
  }

  .get-started-button {
    line-height: 3.5rem;
    text-decoration: none;
    font-weight: 700;
    border: none;
    color: hsl(0, 0%, 100%);
    background-color: #FF0080;
    border-radius: 0.25rem;
    width: 30%;
    display: inline-block;
    position: relative;
    z-index: 1;
    font-size: 0.8rem;
    margin-bottom: 5rem;
  }
}

/* Large desktops (1280px and above) */
@media only screen and (min-width: 80rem) {
  #hero-219 .cs-title {
    font-size: 3.8125rem;
    max-width: 51.8125rem;
    margin: 0 auto 1.5rem 0;
  }

  .get-started-button {
    line-height: 3.5rem;
    text-decoration: none;
    font-weight: 700;
    color: hsl(0, 0%, 100%);
    background-color: #FF0080;
    border-radius: 0.25rem;
    border: none;
    width: 20%;
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    position: relative;
    z-index: 1;
    font-size: 1rem;
    margin-bottom: 5rem;
  }
}


  #hero-219 .cs-text {
    /* 16px - 20px */
    font-size: clamp(1rem, 1.95vw, 1.25rem);
    line-height: 1.5em;
    text-align: left;
    width: 100%;
    /* 464px - 622px */
    max-width: clamp(29rem, 60vw, 38.785rem);
    margin: 0 auto 0 0;
    /* 40px - 48px */
    margin-bottom: clamp(2.5rem, 4vw, 3rem);
    color: #fff;
  }
  #hero-219 .cs-button-solid {
    font-size: 1rem;
    line-height: 3.5rem;
    text-decoration: none;
    font-weight: 700;
    margin: auto;
    color: #fff;
    padding: 0 1.5rem;
    background-color: #FF0080;
    border-radius: 0.25rem;
    display: inline-block;
    position: relative;
    z-index: 1;
  }
  #hero-219 .cs-button-solid:before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 0%;
    background: #000;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 0.25rem;
    transition: width 0.3s;
  }
  #hero-219 .cs-button-solid:hover:before {
    width: 100%;
  }


  .get-started-button:before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 0%;
    background: #000;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 0.25rem;
    transition: width 0.3s;
  }

  .get-started-button:hover:before {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .get-started-button {
    line-height: 1.5rem;
width: 40%;
    font-size: 1rem;
    padding: 10px 20px;
    margin-bottom: 5rem;
  }
}

/* Mobile - 360px */
/* you can copy and paste these styles into the section CSS where you placed the #list-1405 HTML, or you can place it in your global stylesheet so you can reuse the HTML in any Side By Side section on your site. That way when you make changes to it, it will also change everywhere in the site */
@media only screen and (min-width: 0rem) {
  #list-1405 {
    width: 100%;
    margin: 0 0 1.5rem 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }
  #list-1405 .cs-li {
    font-size: var(--bodyFontSize);
    list-style: none;
    line-height: 1.5em;
    width: 100%;
    color: var(--bodyTextColor);
    display: flex;
    justify-content: flex-start;
    /* push icon top the top so if the list item goes to two lines the icon stays at the top */
    align-items: flex-start;
    gap: 0.5rem;
  }
  #list-1405 .cs-icon {
    width: 1.5rem;
    height: auto;
    /* adds extra space between the icon and top of parent so it's more centered */
    margin-top: 1px;
    display: block;
  }
}

/*-- -------------------------- -->
<---       Side By Side         -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #sbsr-1475 {
    padding: var(--sectionPadding);
    overflow: hidden;
  }
  #sbsr-1475 .cs-container {
    width: 100%;
    /* changes to 1280px at desktop */
    max-width: 34.375rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: clamp(3rem, 4vw, 4rem);
  }
  #sbsr-1475 .cs-content {
    /* set text align to left if content needs to be left aligned */
    text-align: left;
    width: 100%;
    max-width: 33.875rem;
    display: flex;
    flex-direction: column;
    /* centers content horizontally, set to flex-start to left align */
    align-items: flex-start;
    position: relative;
    z-index: 10;
  }
  #sbsr-1475 .cs-text {
    margin-bottom: 1rem;
  }
  #sbsr-1475 .cs-text:last-of-type {
    margin-bottom: 2rem;
  }
  #sbsr-1475 .cs-ul {
    width: 100%;
    margin: 0 0 2rem 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 2.5rem;
    row-gap: 0.75rem;
  }
  #sbsr-1475 .cs-li {
    font-size: var(--bodyFontSize);
    list-style: none;
    line-height: 1.5em;
    width: 100%;
    color: var(--bodyTextColor);
    display: flex;
    justify-content: flex-start;
    /* push icon top the top so if the list item goes to two lines the icon stays at the top */
    align-items: flex-start;
    gap: 0.5rem;
  }
  #sbsr-1475 .cs-icon {
    width: 1.5rem;
    height: auto;
    /* adds extra space between the icon and top of parent so it's more centered */
    margin-top: 1px;
    display: block;
  }
  #sbsr-1475 .cs-button-solid {
    font-size: 1rem;
    /* 46px - 56px */
    line-height: clamp(2.875rem, 5.5vw, 3.5rem);
    text-decoration: none;
    font-weight: 700;
    overflow: hidden;
    margin: 0;
    color: #fff;
    padding: 0 3rem;
    border-radius: 1.875rem;
    background-color: #FF0080;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: color 0.3s;
  }
  #sbsr-1475 .cs-button-solid:before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 0%;
    background: black;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    transition: width 0.3s;
  }
  #sbsr-1475 .cs-button-solid:hover:before {
    width: 100%;
  }
  #sbsr-1475 .cs-image-group {
    /* font size scaling. Everything inside the group is in ems so they pull their value for em from the parent (this group div) and when the font is set to a vw, it scales the whole section down and grows proportionally with the screen width, stopping at the final em value declared in the second slot for the min function. */
    font-size: min(2.25vw, 0.83em);
    width: 40.75em;
    min-height: 45em;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
  #sbsr-1475 .cs-picture {
    width: 33.875em;
    height: auto;
    border-radius: 15.625em;
    border: 0.625em solid #ffd3c6;
    background-color: #ffd3c6;
    overflow: hidden;
    display: block;
    position: relative;
  }
  #sbsr-1475 .cs-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  #sbsr-1475 .cs-oval {
    width: 45.4375em;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    filter: brightness(0) saturate(100%) invert(35%) sepia(85%) saturate(6177%) hue-rotate(320deg) brightness(94%) contrast(94%);
    transform: translate(-50%, -50%) rotate(38deg);
  }
  #sbsr-1475 .cs-box {
    text-align: center;
    width: auto;
    /* 16px - 40px */
    padding: clamp(1em, 3vw, 2.5em) clamp(2.5em, 5vw, 4em);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: 3.75em;
    z-index: 10;
  }
  #sbsr-1475 .cs-blob {
    width: 100%;
    height: auto;
    position: absolute;
    filter: brightness(0) saturate(100%) invert(35%) sepia(85%) saturate(6177%) hue-rotate(320deg) brightness(94%) contrast(94%);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #sbsr-1475 .cs-number {
    /* 24px - 49px */
    font-size: clamp(1.25rem, 4.3vw, 3.0625rem);
    line-height: 1.2em;
    font-weight: 900;
    margin: 0;
    color: #fff;
    display: block;
    z-index: 10;
  }
  #sbsr-1475 .cs-desc {
    /* 12px - 16px */
    font-size: clamp(0.75rem, 1.5vw, 1rem);
    line-height: 1.5em;
    text-align: inherit;
    font-weight: bold;
    width: 100%;
    max-width: 20ch;
    color: #fff;
    position: relative;
    z-index: 10;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 64rem) {
  #sbsr-1475 .cs-container {
    max-width: 80rem;
    margin-top: -10%;
    /* set to horizontal arrangement */
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
  #sbsr-1475 .cs-content {
    width: 47%;
    padding: 4.375rem 0;
    align-self: center;
  }
  #sbsr-1475 .cs-image-group {
    font-size: min(1.2vw, 1em);
    /* sends it to the right in the 2nd position */
    order: 2;
  }
}
/*-- -------------------------- -->
<---          Services          -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #services-1354 {
    padding: var(--sectionPadding);
    position: relative;
    z-index: 10;
  }
  #services-1354 .cs-container {
    width: 100%;
    max-width: 80rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: clamp(3rem, 6vw, 4rem);
  }
  #services-1354 .cs-content {
    /* set text align to left if content needs to be left aligned */
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* centers content horizontally, set to flex-start to left align */
    align-items: center;
  }

  #services-1354 .cs-topper {
    color: #767676;
  }
  #services-1354 .cs-title {
    max-width: 25ch;
    margin: 0;
  }
  #services-1354 .cs-card-group {
    margin: 0;
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    /* 16px - 20px */
    gap: clamp(1rem, 2vw, 1.25rem);
  }
  #services-1354 .cs-item {
    text-align: center;
    list-style: none;
    width: 100%;
    height: 19.0625rem;
    margin: 0;
    padding: 0;
    background-color: #000;
    border-radius: 1.5rem;
    /* clips background image corners */
    overflow: hidden;
    box-shadow: 0px 12px 80px 0px rgba(26, 26, 26, 0.08);
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    grid-column: span 12;
    grid-row: span 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
  }
  #services-1354 .cs-item:hover .cs-background:before {
    background-color: #FF0080;
    opacity: 0.84;
  }
  #services-1354 .cs-item:hover .cs-background img {
    transform: scale(1.2);
  }
  #services-1354 .cs-link {
    text-decoration: none;
    width: 100%;
    height: 100%;
    /* padding goes on the link, not the cs-item as is normal. We do this because we want the whole card to be hoverable. So we add the padding to the link tag to create the space inside the card. By adding the space inside the cs-link tag we can make the whole card hoverable since the padding is now contributing to the height and widht of the link */
    padding: 1.5rem;
    /* prevents padding from affecting height and width */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #services-1354 .cs-h3 {
    font-size: 1.5625rem;
    line-height: 1.2em;
    font-weight: bold;
    text-align: inherit;
    margin: 0;
    color: var(--bodyTextColorWhite);
    transition: color 0.3s;
  }
  #services-1354 .cs-span {
    /* forces the h3 to alwasy be two lines */
    display: block;
  }
  #services-1354 .cs-background {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  #services-1354 .cs-background:before {
    /* background color overlay */
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.4;
    top: 0;
    left: 0;
    z-index: 1;
    transition: background-color 0.3s, opacity 0.3s;
  }
  #services-1354 .cs-background img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* Makes img tag act as a background image */
    object-fit: cover;
    transition: transform 0.6s;
  }
}
/* Tablet - 600px */
@media only screen and (min-width: 37.5rem) {
  #services-1354 .cs-item {
    grid-column: span 6;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 64rem) {
  #services-1354 .cs-content {
    text-align: left;
    align-items: flex-start;
  }
  #services-1354 .cs-item {
    grid-column: span 3;
  }
}

/*-- -------------------------- -->
<---           Steps            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #steps-1171 {
    padding: var(--sectionPadding);
    background-color: #FF0080;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  #steps-1171 .cs-container {
    width: 100%;
    /* changes to 1280px at desktop */
    max-width: 44rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 48px - 64px */
    gap: clamp(3rem, 6vw, 4rem);
  }
  #steps-1171 .cs-content {
    /* set text align to left if content needs to be left aligned */
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* centers content horizontally, set to flex-start to left align */
    align-items: center;
  }

  #steps-1171 .cs-topper {
    color: var(--secondary);
  }
  #steps-1171 .cs-title,
  #steps-1171 .cs-text {
    color: var(--bodyTextColorWhite);
  }
  #steps-1171 .cs-text {
    opacity: 0.8;
  }
  #steps-1171 .cs-card-group {
    width: 100%;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1.25rem;
  }
  #steps-1171 .cs-item {
    text-align: center;
    list-style: none;
    display: flex;
    grid-column: span 12;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  #steps-1171 .cs-item:last-of-type:after {
    display: none;
  }
  #steps-1171 .cs-item:nth-of-type(even):after {
    /* scaleX -1 flips it horizontally */
    transform: rotate(-135deg) scaleX(-1);
  }
  #steps-1171 .cs-item:after {
    content: "";
    position: relative;
    display: block;
    /* 54px - 84px */
    width: clamp(3.375rem, 6vw, 5.25rem);
    height: clamp(3.375rem, 6vw, 5.25rem);
    margin-top: 1.25rem;
    background: url("https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/curved-arrow.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    transform: rotate(135deg);
  }
  #steps-1171 .cs-picture {
    margin-bottom: 1.5rem;
    width: 5.5rem;
    height: 5.5rem;
    border: 1px solid rgba(255, 252, 243, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    z-index: 1; /* Ensure the overlay is above the image */
  }

  #steps-1171 .cs-icon {
    width: 2.5rem;
    height: auto;
    display: block;
    z-index: 0; /* Ensure the image is below the overlay */

  }
  #steps-1171 .cs-h3 {
    font-size: 1.25rem;
    line-height: 1.2em;
    text-align: inherit;
    margin: 0 0 0.75rem 0;
    color: var(--bodyTextColorWhite);
  }
  #steps-1171 .cs-item-p {
    font-size: 1rem;
    line-height: 1.5em;
    text-align: inherit;
    max-width: 21.375rem;
    margin: 0;
    color: var(--bodyTextColorWhite);
    opacity: 0.8;
  }
  #steps-1171 .cs-graphic {
    width: 52.5rem;
    height: auto;
    opacity: 0.05;
    position: absolute;
    top: -5rem;
    left: -5rem;
    transform: rotate(-10deg);
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #steps-1171 .cs-item {
    grid-column: span 6;
  }
  #steps-1171 .cs-item:nth-of-type(2):after {
    display: none;
  }
  #steps-1171 .cs-item:nth-of-type(1):after {
    transform: rotate(135deg) scaleX(-1);
  }
  #steps-1171 .cs-item:after {
    margin: 0;
    position: absolute;
    right: -2.5rem;
    top: 0;
    transform: rotate(45deg);
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 64rem) {
  #steps-1171 .cs-container {
    max-width: 80rem;
  }
  #steps-1171 .cs-item {
    grid-column: span 3;
  }
  #steps-1171 .cs-item:nth-of-type(2):after {
    display: block;
    transform: rotate(45deg);
  }
  #steps-1171 .cs-item:nth-of-type(3):after {
    display: block;
    transform: rotate(135deg) scaleX(-1);
  }
  #steps-1171 .cs-item:after {
    right: -3rem;
  }
}

/*-- -------------------------- -->
<---           Stats            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #stats-888 {
    padding: var(--sectionPadding);
  }
  #stats-888 .cs-container {
    width: 100%;
    /* changes to 1280px at desktop */
    max-width: 34.375rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* 48px - 64px */
    gap: clamp(3rem, 6vw, 4rem);
  }
  #stats-888 .cs-content {
    /* set text align to left if content needs to be left aligned */
    text-align: left;
    width: 100%;
    max-width: 33.875rem;
    display: flex;
    flex-direction: column;
    /* centers content horizontally, set to flex-start to left align */
    align-items: flex-start;
  }

  #stats-888 .cs-text {
    margin-bottom: 1rem;
  }
  #stats-888 .cs-text:last-of-type {
    margin-bottom: 2rem;
  }
  #stats-888 .cs-button-solid {
    font-size: 1rem;
    /* 46px - 56px */
    line-height: clamp(2.875rem, 5.5vw, 3.5rem);
    text-decoration: none;
    font-weight: 700;
    text-align: center;
    margin: 0;
    color: #fff;
    min-width: 9.375rem;
    padding: 0 1.5rem;
    background-color: #FF0080;
    border-radius: 0.25rem;
    display: inline-block;
    position: relative;
    z-index: 1;
    /* prevents padding from adding to the width */
    box-sizing: border-box;
  }
  #stats-888 .cs-button-solid:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    background: #000;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 0.25rem;
    transition: width 0.3s;
  }
  #stats-888 .cs-button-solid:hover:before {
    width: 100%;
  }
  #stats-888 .cs-card-group {
    width: 100%;
    max-width: 39.375rem;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 16px - 20px */
    gap: clamp(1rem, 2vw, 1.25rem);
  }
  #stats-888 .cs-item {
    text-align: center;
    list-style: none;
    /* changes at small desktop then again at large desktop */
    width: 100%;
    /* 44px - 60px */
    padding: clamp(2.75rem, 5vw, 3.75rem) 2rem;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    /* clips cs-icon overflowing */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: span 2;
    position: relative;
  }
  #stats-888 .cs-item:nth-of-type(1) {
    background-color: #fef7f4;
    border-radius: 0 5rem 0 5rem;
  }
  #stats-888 .cs-item:nth-of-type(2) {
    background-color: #e8e8e8;
    border-radius: 5rem 0 5rem 0;
  }
  #stats-888 .cs-item:nth-of-type(3) {
    background-color: #fbcebd;
    border-radius: 5rem 0 5rem 0;
  }
  #stats-888 .cs-item:nth-of-type(4) {
    background-color: #f7f7f7;
    border-radius: 0 5rem 0 5rem;
  }
  #stats-888 .cs-number {
    /* 39px - 49px */
    font-size: clamp(2.4375rem, 6vw, 3.0625rem);
    line-height: 1.2em;
    text-align: inherit;
    font-weight: 900;
    margin: 0 0 0.75rem 0;
    color: var(--headerColor);
  }
  #stats-888 .cs-desc {
    /* 16px -20px */
    font-size: clamp(1rem, 2vw, 1.25rem);
    line-height: 1.2em;
    font-weight: 700;
    text-align: inherit;
    margin: 0;
    color: var(--bodyTextColor);
  }
}
/* Tablet - 650px */
@media only screen and (min-width: 40.625rem) {
  #stats-888 .cs-item {
    grid-column: span 1;
  }
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 64rem) {
  #stats-888 .cs-container {
    max-width: 80rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  #stats-888 .cs-content {
    /* sends it to the right in the 2nd position */
    order: 2;
  }
}

/*-- -------------------------- -->
<---           Logos            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #logos-572 {
    /* 60px - 130px */
    padding: clamp(3.75rem, 8vw, 8.125rem) 1rem;
    background-color: #FF0080;
  }
  #logos-572 .cs-container {
    width: 100%;
    max-width: 80rem;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* 48px - 64px */
    gap: clamp(3rem, 6vw, 4rem);
  }
  #logos-572 .cs-logo {
    width: auto;
    max-width: 90%;
    height: auto;
    margin: 0;
    display: block;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #logos-572 .cs-container {
    justify-content: space-between;
  }
  #logos-572 .cs-logo {
    width: 20%;
    /* the max width becomes whatever the actual width of the image is */
    max-width: max-content;
    height: auto;
    display: block;
  }
}

/*-- -------------------------- -->
<---          Reviews           -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #reviews-727 {
    padding: var(--sectionPadding);
  }
  #reviews-727 .cs-container {
    width: 100%;
    /* changes to 1280px at tablet */
    max-width: 34.375rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 48px - 64px */
    gap: clamp(3rem, 6vw, 4rem);
  }
  #reviews-727 .cs-content {
    /* set text align to left if content needs to be left aligned */
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* centers content horizontally, set to flex-start to left align */
    align-items: center;
  }

  #reviews-727 .cs-title {
    /* cs-title override.  cs-topper cs-title and first cs-text should be removed and put into your global css sheet so it can control every instance of them on your site and is consistent. This second cs-title is a section specific override that stays inside this stitch */
    max-width: 20ch;
  }
  #reviews-727 .cs-card-group {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
  }
  #reviews-727 .cs-item {
    list-style: none;
    width: 100%;
    margin: 0;
    /* 32px - 48px top & bottom */
    /* 16px - 32px left & right */
    padding: clamp(2rem, 7vw, 3rem) clamp(1rem, 4vw, 2rem);
    /* makes padding not affect height and width */
    box-sizing: border-box;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  #reviews-727 .cs-icon {
    /* 30px - 40px */
    width: clamp(1.875rem, 4vw, 2.5rem);
    height: auto;
    margin: 0 0 2rem 0;
    display: block;
  }
  #reviews-727 .cs-h3 {
    /* 20px - 25px */
    font-size: clamp(1.25rem, 4vw, 1.5625rem);
    line-height: 1.2em;
    font-weight: 700;
    margin: 0 0 1.5rem 0;
    color: var(--headerColor);
  }
  #reviews-727 .cs-item-text {
    /* 14px - 16px */
    font-size: clamp(0.875rem, 4vw, 1rem);
    line-height: 1.5em;
    margin: 0 0 3rem 0;
    color: black;
  }
  #reviews-727 .cs-name {
    font-size: 1.25rem;
    line-height: 1.2em;
    font-weight: 500;
    margin: auto 0 0 0;
    color: var(--headerColor);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    position: relative;
  }
  #reviews-727 .cs-name:before {
    content: "";
    width: 0.5rem;
    height: 1px;
    background: currentColor;
    display: block;
    position: relative;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #reviews-727 .cs-container {
    max-width: 80rem;
  }
  #reviews-727 .cs-card-group {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  #reviews-727 .cs-item {
    width: 48.5%;
  }
}

/*-- -------------------------- -->
<---           Blog             -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #blog-846 {
    padding: var(--sectionPadding);
  }
  #blog-846 .cs-container {
    width: 100%;
    /* changes to 1280px at tablet */
    max-width: 34.375rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 48px - 64px */
    gap: clamp(3rem, 6vw, 4rem);
  }
  #blog-846 .cs-content {
    /* set text align to left if content needs to be left aligned */
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* centers content horizontally, set to flex-start to left align */
    align-items: center;
  }

  #blog-846 .cs-title {
    margin: 0;
  }
  #blog-846 .cs-card-group {
    width: 100%;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    /* 16px - 20px */
    gap: clamp(1rem, 2vw, 1.25rem);
  }
  #blog-846 .cs-item {
    list-style: none;
    width: 100%;
    border-radius: 1.5rem 0 1.5rem 0;
    /* clips the img corners */
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
    grid-column: span 12;
    position: relative;
    z-index: 1;
  }
  #blog-846 .cs-item:hover .cs-picture img {
    transform: scale(1.1);
  }
  #blog-846 .cs-picture {
    width: 100%;
    /* 180px - 290px */
    height: clamp(11.25rem, 50vw, 18.125rem);
    /* prevents image from overflowing parent when it scales on hover */
    overflow: hidden;
    display: block;
    position: relative;
  }
  #blog-846 .cs-picture img {
    width: 100%;
    height: 100%;
    /* makes it act like a background image */
    object-fit: cover;
    display: block;
    transition: transform 0.6s;
  }
  #blog-846 .cs-item-content {
    /* 24px - 40px top and bottom */
    /* 20px - 24px left & right */
    padding: clamp(1.5rem, 5vw, 2.5rem) clamp(1rem, 3vw, 1.25rem);
    position: relative;
    z-index: 10;
  }
  #blog-846 .cs-date {
    font-size: 1rem;
    line-height: 1.2em;
    font-weight: 700;
    margin: 0 0 1.25rem 0;
    color: #767676;
    display: block;
  }
  #blog-846 .cs-h3 {
    font-size: 1.25rem;
    line-height: 1.2em;
    font-weight: 700;
    margin: 0 0 0.75rem 0;
    color: var(--headerColor);
    display: block;
  }
  #blog-846 .cs-item-text {
    font-size: 1rem;
    line-height: 1.5em;
    margin: 0 0 1.25rem 0;
    color: var(--bodyTextColor);
    display: block;
  }
  #blog-846 .cs-link {
    font-size: 1rem;
    line-height: 1.2em;
    font-weight: bold;
    text-decoration: none;
    margin: 0;
    color: #FF0080;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }
  #blog-846 .cs-link:hover .cs-icon {
    transform: translateX(0.625rem);
  }
  #blog-846 .cs-icon {
    width: 1.25rem;
    height: auto;
    display: block;
    transition: transform 0.3s;
  }
  #blog-846 .cs-button-solid {
    font-size: 1rem;
    /* 46px - 56px */
    line-height: clamp(2.875rem, 5.5vw, 3.5rem);
    text-decoration: none;
    font-weight: 700;
    text-align: center;
    margin: 0;
    color: #fff;
    min-width: 9.375rem;
    padding: 0 1.5rem;
    background-color: #FF0080;
    border-radius: 0.25rem;
    display: inline-block;
    position: relative;
    z-index: 1;
    /* prevents padding from adding to the width */
    box-sizing: border-box;
  }
  #blog-846 .cs-button-solid:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    background: #000;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 0.25rem;
    transition: width 0.3s;
  }
  #blog-846 .cs-button-solid:hover:before {
    width: 100%;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #blog-846 .cs-container {
    max-width: 80rem;
  }
  #blog-846 .cs-item {
    grid-column: span 6;
  }
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 64rem) {
  #blog-846 .cs-item {
    grid-column: span 3;
  }
}
/* Large Desktop - 1300px */
@media only screen and (min-width: 81.25rem) {
  #blog-846 .cs-item {
    display: flex;
    flex-direction: column;
    grid-column: span 3;
  }
  #blog-846 .cs-item:nth-of-type(even) .cs-picture {
    order: 2;
  }
}
#logos-572 {
  padding: 20px;
}
#sbsr-1475 .cs-li{
  color: 'none';
}
.cs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.cs-logo {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  flex: 1 1 150px; 
  max-width: 200px; 
  cursor: pointer;
}


@media screen and (max-width: 768px) {
  .cs-container {
    gap: 10px;
  }
}

@media screen and (max-width: 480px) {
  .cs-logo {
    flex: 1 1 120px; /* Smaller size for logos */
  }
}