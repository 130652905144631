@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Montserrat", sans-serif !important;
}
.ant-layout{
  font-family: "Montserrat", sans-serif !important;

}