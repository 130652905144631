/*-- -------------------------- -->
<---        Content Page        -->
<--- -------------------------- -*/



/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #content-page-852 {
        padding: var(--sectionPadding);
        background-color: #fff;
        /* clips the wave background from causing overflow issues when it goes off screen */
        overflow: hidden;
        position: relative;
        z-index: 1;
    }
    #content-page-852 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
        position: relative;
    }
    #content-page-852 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: left;
        width: 100%;
        max-width: 46.125rem;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: flex-start;
        font-family: "Montserrat", sans-serif;

    }
    #content-page-852 .cs-title {
        font-size: var(--headerFontSize);
        font-weight: 900;
        line-height: 1.2em;
        text-align: inherit;
        width: 100%;
        max-width: 100%;
        margin: 0 0 1rem 0;
        color: var(--headerColor);
        position: relative;
        font-family: "Montserrat", sans-serif;

    }
    #content-page-852 h2,
    #content-page-852 h3,
    #content-page-852 h4,
    #content-page-852 h5,
    #content-page-852 h6 {
        font-weight: 700;
        text-align: inherit;
        margin: 0 0 1rem 0;
        color: var(--headerColor);
        font-family: "Montserrat", sans-serif;

    }
    #content-page-852 h2 {
        font-size: 2rem;
        margin-top: 2rem;
    }
    #content-page-852 h3 {
        font-size: 1.5rem;
        color: #FF0080;
    }
    #content-page-852 h4,
    #content-page-852 h5,
    #content-page-852 h6 {
        font-size: 1.25rem;
    }
    #content-page-852 .cs-button-solid {
        margin-bottom: 2rem;
    }
    #content-page-852 .cs-no-margin {
        margin: 0;
    }
    #content-page-852 .cs-color {
        color: #FF0080;
    }
    #content-page-852 p {
        font-size: var(--bodyFontSize);
        line-height: 1.5em;
        text-align: inherit;
        width: 100%;
        margin: 0 0 1rem 0;
        color: var(--bodyTextColor);
        font-family: "Montserrat", sans-serif;

    }
    #content-page-852 p:last-of-type {
        margin-bottom: 2rem;
    }
    #content-page-852 p a {
        font-size: inherit;
        line-height: inherit;
        text-decoration: underline;
        color: #FF0080;
    }
    #content-page-852 ol,
    #content-page-852 ul {
        padding-left: 1.5rem;
        margin: 0 0 2rem 0;
        color: var(--bodyTextColor);
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    #content-page-852 ul li {
        list-style: none;
        color: inherit;
        position: relative;
    }
    #content-page-852 ul li:before {
        /* custom list bullet */
        content: "";
        width: 3px;
        height: 3px;
        background: currentColor;
        opacity: 1;
        border-radius: 50%;
        position: absolute;
        display: block;
        top: 0.625rem;
        left: -0.75rem;
    }
    #content-page-852 img {
        width: 100%;
        height: auto;
        display: block;
    }
    #content-page-852 .cs-image-group {
        width: 50%;
        max-width: 27.0625rem;
        display: none;
        flex-direction: column;
        gap: 1.25rem;
        position: relative;
    }
    #content-page-852 .cs-image-group:after {
        /* colored box */
        content: "";
        width: 100%;
        /* 177px - 258px */
        height: clamp(11.0625rem, 19vw, 16.125rem);
        background: #FF0080;
        border-radius: 6.25rem 0 6.25rem;
        opacity: 0.15;
        display: block;
    }
    #content-page-852 .cs-picture {
        width: 100%;
        /* 300px - 520px */
        height: clamp(18.75rem, 40vw, 32.5rem);
        box-shadow: 0px 3.3478px 50.2169px rgba(0, 0, 0, 0.16);
        /* 125px - 200px */
        border-radius: 0 clamp(7.8125rem, 15vw, 12.5rem) 0
            clamp(7.8125rem, 15vw, 12.5rem);
        /* prevents border from affecting height and width */
        box-sizing: border-box;
        /* clips img tag corners */
        overflow: hidden;
        display: block;
        position: relative;
    }
    #content-page-852 .cs-picture img {
        width: 100%;
        height: 100%;
        /* makes it act like a background image */
        object-fit: cover;
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    #content-page-852 .cs-container {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    #content-page-852 .cs-content {
        flex: none;
        width: 60%;
        /* sens it to the right in the 2nd position */
        order: 2;
    }
    #content-page-852 .cs-image-group {
        display: flex;
    }
}

                                /* FAQ */
                                /*-- -------------------------- -->
<---       Meet The Team        -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #meet-us-1021 {
        padding: var(--sectionPadding);
        background-color: #1a1a1a;
    }
    #meet-us-1021 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
    }
    #meet-us-1021 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
    }
    #meet-us-1021 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: center;
    }
    #meet-us-1021 .cs-graphic {
        width: 100%;
        max-width: 26.82352941rem;
        margin: 0 0 1rem 0;
    }
    #meet-us-1021 .cs-title {
        font-size: var(--headerFontSize);
        font-weight: 900;
        line-height: 1.2em;
        text-align: inherit;
        max-width: 43.75rem;
        margin: 0 0 1rem 0;
        color: var(--headerColor);
        position: relative;
    }
    #meet-us-1021 .cs-text {
        font-size: var(--bodyFontSize);
        line-height: 1.5em;
        text-align: inherit;
        width: 100%;
        max-width: 40.625rem;
        margin: 0;
        color: var(--bodyTextColor);
    }
    #meet-us-1021 .cs-title,
    #meet-us-1021 .cs-text {
        color: var(--bodyTextColorWhite);
    }
    #meet-us-1021 .cs-text {
        opacity: 0.8;
    }
    #meet-us-1021 .cs-card-group {
        width: 100%;
        padding: 0;
        margin: 0;
        place-items: center; /* Centers both horizontally and vertically */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        /* 16px - 20px */
        gap: clamp(1rem, 2vw, 1.25rem);
    }
    #meet-us-1021 .cs-item {
        list-style: none;
        width: 100%;
        place-items: center; /* Centers both horizontally and vertically */

        max-width: 25.8125rem;
        position: relative;
        z-index: 1;
    }
    #meet-us-1021 .cs-item:hover .fahida-picture {
        background-color: #000;
    }
    #meet-us-1021 .cs-item:hover .fahida-picture img {
        transform: scale(1.1);
        opacity: 0.6;
    }
    #meet-us-1021 .fahida-info {
        width: 85.5%;
        /* 16px - 24px */
        padding: clamp(1rem, 2vw, 1.5rem);
        margin: -3.75rem auto 0;
        box-sizing: border-box;
        background-color: #1a1a1a;
        border-top: 4px solid var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10;
    }
    #meet-us-1021 .fahida-name {
        /* 16px - 25px */
        font-size: clamp(1rem, 2vw, 1.5625rem);
        font-weight: 700;
        line-height: 1.2em;
        /* 4px - 8px */
        margin: 0 0 clamp(0.25rem, 1vw, 0.5rem);
        color: var(--bodyTextColorWhite);
        display: block;
        transition: color 0.3s;
    }
    #meet-us-1021 .fahida-job {
        /* 14px - 16px */
        font-size: clamp(0.875rem, 1.5vw, 1rem);
        line-height: 1.5em;
        color: var(--bodyTextColorWhite);
        opacity: 0.8;
        display: block;
    }
    #meet-us-1021 .fahida-picture {
        width: 100%;
        /* 246px - 500px */
        height: clamp(15.375rem, 40vw, 32.25rem);
        overflow: hidden;
        display: block;
        position: relative;
        transition: background-color 0.3s;
        z-index: -1;
    }
    #meet-us-1021 .fahida-picture img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        /* makes it behave like a background imahe */
        object-fit: cover;
        /* makes the image position itself at the top of the parent */
        object-position: top;
        transition:
            transform 0.6s,
            opacity 0.3s;
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    #meet-us-1021 .cs-card-group {
        display: grid;
        place-items: center; /* Centers both horizontally and vertically */

        grid-template-columns: repeat(12, 1fr);
        width: 100%;
    }
    #meet-us-1021 .cs-item {
      place-items: center; /* Centers both horizontally and vertically */
    }
}

                                
/* faq */
/*-- -------------------------- -->
<---            FAQ             -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #faq-1720 {
      padding: var(--sectionPadding);
      position: relative;
    }
    #faq-1720 .cs-container {
      width: 100%;
      max-width: 80rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* 48px - 64px */
      gap: clamp(3rem, 6vw, 4rem);
      position: relative;
      z-index: 1;
    }
    #faq-1720 .cs-content {
      /* set text align to left if content needs to be left aligned */
      text-align: left;
      width: 100%;
      display: flex;
      flex-direction: column;
      /* centers content horizontally, set to flex-start to left align */
      align-items: flex-start;
    }
    #faq-1720 .cs-title {
      margin: 0;
    }
    #faq-1720 .cs-flex-group {
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      font-family: "Montserrat", sans-serif;
      /* 16px - 20px */
      gap: clamp(1rem, 2.3vw, 1.25rem);
      position: relative;
    }
    #faq-1720 .cs-wrapper {
      width: 100%;
      position: relative;
    }
    #faq-1720 .cs-button-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      /* 16px - 20px */
      gap: clamp(1rem, 2.5vw, 1.25rem);
      /* prevents flexbox from squishing it */
      flex: none;
    }
    #faq-1720 .cs-flex {
      width: 100%;
      /* 24px - 32px */
      padding: clamp(1.5rem, 3vw, 2rem);
      background-color: #F7F7F7;
      display: flex;
      flex-direction: column;
      order: 3;
      gap: 1.25rem;
      font-family: "Montserrat", sans-serif;

    }
    #faq-1720 .cs-option {
      /* 16px - 20px */
      font-size: clamp(1rem, 2vw, 1.25rem);
      line-height: 1.2em;
      font-weight: 700;
      padding: 0;
      color: var(--bodyTextColor);
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      position: relative;
      z-index: 1;
      font-family: "Montserrat", sans-serif;
      transition: color .3s;
    }
    #faq-1720 .cs-option:hover {
      color: #FF0080;
      cursor: pointer;
    }
    #faq-1720 .cs-option.cs-active {
      color: #FF0080;
    }
    #faq-1720 .cs-picture {
      width: 100%;
      /* 328px - 450px */
      height: clamp(20.5rem, 30vw, 28.125rem);
      /* 16px - 20px */
      margin: 0 0 clamp(1rem, 2.4vw, 1.25rem);
      order: 2;
      position: relative;
      z-index: 1;
    }
    #faq-1720 .cs-picture img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    #faq-1720 .cs-faq-group {
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      order: 3;
      gap: 0.5rem;
      transition: transform 0.7s,
                  opacity 0.3s,
                  visibility 0.5s,
                  top 0.3s,
                  left 0.3s;
      /* makes the transfrom scaling orgin the top left corner, dictates the direction by which the scale transforms animate towards */
      transform-origin: top;
      transform-style: preserve-3d;
      perspective: 900px;
    }
    #faq-1720 .cs-faq-group.cs-hidden {
      /* by using visibility:hidden instead of display:none, we can see the animations from the opacity and transforms, display:none won't render animations. */
      visibility: hidden;
      /* prevents the mouse from interacting with it */
      pointer-events: none;
      /* hidden galleries have a 0 opacity, and we animate the opacity to 1 when they become active */
      opacity: 0;
      /* this top and left value help control the animation, by setting it to position absolute and left 0, the FAQ won't fly off screen to the left, it will stop its position to be at the left edge of the .cs-flex-group (left: 0). Same for the bottom:0 value, the FAQ won't go past that position when it animates */
      top: 0;
      left: 0;
      position: absolute;
      /* prevents the hidden galleries from overflowing the section, and makes a nice animations to transition to and from */
      transform: scaleY(0);
    }
    #faq-1720 .cs-faq-group.cs-hidden .cs-faq-item {
      transform: rotateX(270deg);
      opacity: 0;
    }
    #faq-1720 .cs-faq-item {
      list-style: none;
      width: 100%;
      /* clips all corners of the button that overlap the rounded border */
      overflow: hidden;
      opacity: 1;
      top: 0;
      transform: rotateX(0deg);
      transition: transform 0.6s,
                  opacity 0.3s;
    }
    #faq-1720 .cs-faq-item:nth-of-type(2) {
      transition-delay: 0.1s;
    }
    #faq-1720 .cs-faq-item:nth-of-type(3) {
      transition-delay: 0.2s;
    }
    #faq-1720 .cs-faq-item:nth-of-type(4) {
      transition-delay: 0.3s;
    }
    #faq-1720 .cs-faq-item:nth-of-type(5) {
      transition-delay: 0.4s;
    }
    #faq-1720 .cs-faq-item:nth-of-type(6) {
      transition-delay: 0.5s;
    }
    #faq-1720 .cs-faq-item:nth-of-type(7) {
      transition-delay: 0.6s;
    }
    #faq-1720 .cs-faq-item:nth-of-type(8) {
      transition-delay: 0.7s;
    }
    #faq-1720 .cs-faq-item:nth-of-type(9) {
      transition-delay: 0.8s;
    }
    #faq-1720 .cs-faq-item:nth-of-type(10) {
      transition-delay: 0.9s;
    }
    #faq-1720 .cs-faq-item.active .cs-button {
      color: #FF0080;
    }
    #faq-1720 .cs-faq-item.active .cs-button:before {
      background-color: #FF0080;
      transform: rotate(315deg);
    }
    #faq-1720 .cs-faq-item.active .cs-button:after {
      background-color: #FF0080;
      transform: rotate(-315deg);
    }
    #faq-1720 .cs-faq-item.active .cs-item-p {
      height: auto;
      /* 20px - 24px bottom */
      /* 16px - 24px left & right */
      padding: 0 clamp(1rem, 2vw, 1.5rem) clamp(1.25rem, 1.3vw, 1.5rem);
      opacity: 1;
    }
    #faq-1720 .cs-button {
      /* 16px - 20px */
      font-size: clamp(1rem, 2vw, 1.25rem);
      line-height: 1.2em;
      text-align: left;
      font-weight: bold;
      /* 16px - 24px top & bottom */
      /* 16px - 20px left & right */
      padding: clamp(1rem, 2vw, 1.5rem) clamp(1rem, 2vw, 1.25rem);
      background-color: #f7f7f7;
      border: none;
      color: var(--headerColor);
      display: block;
      width: 100%;
      position: relative;
      transition: background-color 0.3s, color 0.3s;
    }
    #faq-1720 .cs-button:hover {
      cursor: pointer;
    }
    #faq-1720 .cs-button:before {
      /* left line */
      content: "";
      width: 0.5rem;
      height: 0.125rem;
      background-color: var(--headerColor);
      opacity: 1;
      border-radius: 50%;
      position: absolute;
      display: block;
      top: 50%;
      right: 1.5rem;
      transform: rotate(45deg);
      /* animate the transform from the left side of the x axis, and the center of the y */
      transform-origin: left center;
      transition: transform 0.5s;
    }
    #faq-1720 .cs-button:after {
      /* right line */
      content: "";
      width: 0.5rem;
      height: 0.125rem;
      background-color: var(--headerColor);
      opacity: 1;
      border-radius: 50%;
      position: absolute;
      display: block;
      top: 50%;
      right: 1.3125rem;
      transform: rotate(-45deg);
      /* animate the transform from the right side of the x axis, and the center of the y */
      transform-origin: right center;
      transition: transform 0.5s;
    }
    #faq-1720 .cs-button-text {
      width: 80%;
      display: block;
      font-family: "Montserrat", sans-serif;

    }
    #faq-1720 .cs-item-p {
      /* 14px - 16px */
      font-size: clamp(0.875rem, 1.5vw, 1rem);
      line-height: 1.5em;
      width: 100%;
      height: 0;
      margin: 0;
      /* 16px - 24px */
      padding: 0 clamp(1rem, 2vw, 1.5rem);
      opacity: 0;
      background-color: #f7f7f7;
      color: black;
      /* clips the text so it doesn't show up */
      overflow: hidden;
      transition: opacity 0.3s, padding-bottom 0.3s;
    }
  }
  /* Inbetween - 600px */
  @media only screen and (min-width: 37.5rem) {
    #faq-1720 .cs-button-group {
      width: 80%;
      max-width: 22.0625rem;
      flex-direction: row;
      align-items: stretch;
      flex: none;
    }
    #faq-1720 .cs-content {
      width: 100%;
    }
    #faq-1720 .cs-picture {
      /* 300px - 493px */
      min-height: clamp(18.75rem, 30vw, 30.8125rem);
      margin: 0;
      height: 100%;
      flex: none;
      order: 3;
    }
  }
  /* Desktop - 1024px */
  @media only screen and (min-width: 48rem) {
    #faq-1720 .cs-flex-group {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      /* 20px - 80px */
      font-family: "Montserrat", sans-serif;
      column-gap: clamp(1.25rem, 3vw, 5rem);
    }
    #faq-1720 .cs-button-group {
      flex-direction: column;
      flex: auto;
    }
    #faq-1720 .cs-picture {
      max-height: 31.25rem;
    }
  }
                                  