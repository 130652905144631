/*-- -------------------------- -->
<---          Banner            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    .banner-712 {
        /* 175px - 200px top */
        padding: clamp(10.9375rem, 10vw, 12.5rem) 1rem 6.25rem;
        position: relative;
        z-index: 1;
    }
    .banner-712 .cs-container {
        text-align: center;
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
    }
    .banner-712 .cs-int-title {
        /* 39px - 61px */
        font-size: clamp(2.4375rem, 6.4vw, 3.8125rem);
        font-weight: 900;
        line-height: 1.2em;
        text-align: inherit;
        margin: 0;
        color: var(--bodyTextColorWhite);
        position: relative;
    }
    .banner-712 .cs-background {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .banner-712 .cs-background:before {
        /* background color overlay */
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        background: #000;
        opacity: 0.75;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .banner-712 .cs-background img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        /* Makes img tag act as a background image */
        object-fit: cover;
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    .banner-712 .cs-background:before {
        opacity: 1;
        background: linear-gradient(
            90.01deg,
            rgba(0, 0, 0, 0.9) 16.86%,
            rgba(0, 0, 0, 0) 100%
        );
    }
}

                                
.article-container {
    color: #333;
    line-height: 1.6;
  }
  
  .banner {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .cs-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .cs-int-title {
    font-size: 2.5em;
    font-weight: bold;
    padding: 20px 0;
  }
  
  .cs-background img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .article-content {
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .article-content h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .article-content p {
    margin-bottom: 20px;
    font-size: 1.1em;
  }
  
  .article-content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .article-content li {
    margin-bottom: 10px;
    font-size: 1.1em;
  }

  .scroll-line {
    height: 2px;
    margin-top: 10%;
    background: #FF0080;
    width: 0%;
    position: fixed;
top: 0;
  }
 
  .site-wrap {
    max-width: 100vw;
    background: white;
    padding: 10%;
    text-align: justify;
  }
  
  .align-left {
    float: left;
    margin-right: 20px;
  }
  
  .align-right {
    float: right;
    margin-left: 20px;
  }
  
  .slide-in {
    opacity: 0;
    transition: all .5s;
  }
  
  .align-left.slide-in {
    transform: translateX(-30%) scale(0.95);
  }
  
  .align-right.slide-in {
    transform: translateX(30%) scale(0.95);
  }
  
  .slide-in.active {
    opacity: 1;
    transform: translateX(0%) scale(1);
  }