/* General Styles */
.profile-container {
  margin: 0;
  padding: 0;
  padding-top: 10%;
  font-size: 18px;
  line-height: 1.5;
}

.profile-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.h2, .h4 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.h2 {
  font-size: 2.5rem;
}

.h4 {
  font-size: 1.8rem;
}
.p {
  font-size: 1.3rem;
}

/* User Details Section */
.user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.profile-picture-container {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  /* overflow: hidden; */
  cursor: pointer;
  margin-bottom: -15%;
}

.profile-picture-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 8px;
  text-align: center;
}

/* Form Styles */
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input,
.select {
  width: 400px;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.button {
  padding: 12px 24px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #555;
}

/* Link Styles */
.add-a-pet {
  border-left: 5px solid purple; /* Add purple border to the left */

  color: purple;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
}

.add-a-pet:hover {
  text-decoration: underline;
}