/*-- -------------------------- -->
<---          Footer            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #cs-footer-269 {
        padding: 1.5rem 1rem;
        background-color: #FF0080;
    }
    #cs-footer-269 .cs-container {
        width: 100%;
        max-width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
    #cs-footer-269 .cs-logo-group {
        grid-column: 1 / -1;
    }
    #cs-footer-269 .cs-logo {
        width: 8rem;
        height: auto;
        display: block;
        margin-bottom: 0.5rem;
    }
    #cs-footer-269 .cs-text {
        font-size: 0.75rem;
        line-height: 1.2;
        margin: 0;
        color: var(--bodyTextColorWhite);
    }
    #cs-footer-269 .cs-nav,
    #cs-footer-269 .cs-contact {
        padding: 0;
        margin: 0;
    }
    #cs-footer-269 .cs-nav-li,
    #cs-footer-269 .cs-contact-li {
        list-style: none;
        line-height: 1.2;
        margin-bottom: 0.25rem;
    }
    #cs-footer-269 .cs-header {
        font-size: 0.875rem;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 0.25rem;
        color: var(--bodyTextColorWhite);
    }
    #cs-footer-269 .cs-nav-link,
    #cs-footer-269 .cs-contact-link {
        font-size: 0.75rem;
        text-decoration: none;
        line-height: 1.2;
        color: var(--bodyTextColorWhite);
    }
    #cs-footer-269 .cs-nav-link:hover,
    #cs-footer-269 .cs-contact-link:hover {
        text-decoration: underline;
    }
    #cs-footer-269 .cs-address {
        font-size: 0.75rem;
        line-height: 1.2;
        margin-bottom: 0.25rem;
    }
    #cs-footer-269 .cs-social-group {
        grid-column: 1 / -1;
        justify-self: center;
    }
    #cs-footer-269 .cs-social {
        display: flex;
        gap: 0.5rem;
    }
    #cs-footer-269 .cs-social-link {
        width: 1.5rem;
        height: 1.5rem;
        background-color: #4e4b66;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s;
    }
    #cs-footer-269 .cs-social-link:hover {
        background-color: #FF0080;
    }
    #cs-footer-269 .cs-social-img {
        height: 0.75rem;
        width: auto;
    }
}

/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    #cs-footer-269 {
        padding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
    }
    #cs-footer-269 .cs-container {
        max-width: 80rem;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
    }
    #cs-footer-269 .cs-logo-group {
        grid-column: span 2;
    }
    #cs-footer-269 .cs-logo {
        width: clamp(13.125rem, 8vw, 15rem);
        margin-bottom: 1rem;
    }
    #cs-footer-269 .cs-text {
        font-size: 1rem;
        line-height: 1.5em;
        max-width: 33.75rem;
    }
    #cs-footer-269 .cs-nav-li,
    #cs-footer-269 .cs-contact-li {
        margin-bottom: 0.5rem;
    }
    #cs-footer-269 .cs-header {
        font-size: 1rem;
        margin-bottom: 0.875rem;
    }
    #cs-footer-269 .cs-nav-link,
    #cs-footer-269 .cs-contact-link,
    #cs-footer-269 .cs-address {
        font-size: 1rem;
    }
    #cs-footer-269 .cs-social-group {
        grid-column: 4;
        justify-self: end;
    }
}