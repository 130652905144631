/* CreateAnimal.css */
.create-animal-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .create-animal-form div {
    margin-bottom: 16px;
  }
  
  .create-animal-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .create-animal-form .error {
    color: #ff4d4f;
    font-size: 14px;
  }
  
  .ant-input {
    width: 100%;
  }
  
  .create-animal-form button {
    width: 100%;
  }
  
  .required-asterisk {
    color: red;
    margin-left: 4px; /* Space between the label text and the asterisk */
  }
  