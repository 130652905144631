/* ProfessionalCards.css */

.card {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 8px;
  padding: 10px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 180px;
}

@media screen and (max-width: 576px) {
  .card {
 
    width: 100%;
    max-width: 100%;
  }
}


.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 8px;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.card h3 {
  font-size: 0.9rem;
  margin: 0 0 4px 0;
  color: #333;
}

.card p {
  font-size: 0.7rem;
  color: #666;
  margin: 0 0 2px 0;
}

.free-label {
  background-color: #39ff14;
  color: #006400;
  padding: 2px 4px;
  border-radius: 2px;
  font-weight: bold;
  display: inline-block;
  font-size: 0.6rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 4px;
}

/* Specific styles for different card types */
.animal-card, .walker-card, .sitter-card, .vet-card, .trainer-card, .clinic-card {
  border-top: 2px solid #ccc;
}

.animal-card { border-top-color: #ff6b6b; }
.walker-card { border-top-color: #4ecdc4; }
.sitter-card { border-top-color: #45b7d1; }
.vet-card { border-top-color: #f9a826; }
.trainer-card { border-top-color: #6c5ce7; }
.clinic-card { border-top-color: #a55eea; }

/* Responsive grid layout */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .card {
      max-width: 140px;
      padding: 8px;
      margin: 4px;  /* Reduced margin */
  }

  .card h3 {
      font-size: 0.8rem;
  }

  .card p {
      font-size: 0.6rem;
  }

  .free-label {
      font-size: 0.5rem;
      padding: 1px 3px;
  }

  .card-container {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      gap: 8px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card {
      max-width: 160px;
  }

  .card-container {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 12px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card {
      max-width: 180px;
  }

  .card-container {
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
      gap: 16px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card {
      max-width: 200px;
  }

  .card-container {
      grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  }
}

/* Loading state */
.card p:only-child {
  text-align: center;
  font-style: italic;
  color: #999;
}
