/* CategoryPage.css */

.theme-default {
    --primary-color: #3498db;
    --secondary-color: #2c3e50;
    --background-color: #f4f4f4;
    --card-background: #ffffff;
    --text-color: #333333;
    --shadow-color: rgba(0, 0, 0, 0.1);
  }
  
  .category-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--background-color);
  }
  
  .category-title {
    padding-top: 13%;
    font-size: 2.2rem;
    color: var(--secondary-color);
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 700;
  }
  
  .professional-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .professional-link {
    text-decoration: none;
    color: var(--text-color);
    transition: transform 0.3s ease;
  }
  
  .professional-link:hover {
    transform: translateY(-5px);
  }
  
  .professional-card {
    background-color: var(--card-background);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px var(--shadow-color);
    transition: box-shadow 0.3s ease;
  }
  
  .professional-card:hover {
    box-shadow: 0 6px 12px var(--shadow-color);
  }
  
  .professional-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .professional-card h3 {
    font-size: 1.2rem;
    margin: 1rem;
    color: var(--secondary-color);
  }
  
  .professional-card p {
    font-size: 0.9rem;
    margin: 0.5rem 1rem;
    color: var(--text-color);
  }
  
  .location-match {
    background-color: var(--primary-color);
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    display: inline-block;
    margin: 0.5rem 1rem;
  }
  
  @media (max-width: 768px) {
    .category-title {
      font-size: 2rem;
    }
  
    .professional-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    .category-page {
      padding: 1rem;
    }
  
    .category-title {
      font-size: 1.5rem;
    }
  
    .professional-grid {
      grid-template-columns: 1fr;
    }
  }