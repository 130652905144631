/* AnimalRescueStyles.css */

.rescue-article {
    max-width: 56rem;
    margin: 0 auto;
    padding: 2rem 1rem;
    background-color: white;
    color: #333;
      padding-top: 13%;
  }
  
  .article-header {
    margin-bottom: 2rem;
  }
  
  .article-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .author-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .author-image {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
  
  .author-name {
    font-weight: 600;
  }
  
  .author-role {
    font-size: 0.875rem;
    color: #666;
  }
  
  .header-image {
    width: 100%;
    height: 16rem;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .article-section {
    margin-bottom: 2rem;
  }
  
  .section-title {
    font-size: 1.875rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .article-section p {
    margin-bottom: 1rem;
    line-height: 1.625;
    color: #444;
  }
  
  .section-image {
    width: 100%;
    height: 12rem;
    object-fit: cover;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .article-section ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .article-section li {
    margin-bottom: 0.5rem;
    color: #444;
  }
  
  .rescue-alert {
    margin-bottom: 2rem;
    border-left: 4px solid #FF0080 !important;
  }
  
  .article-footer {
    border-top: 1px solid #e5e5e5;
    padding-top: 1rem;
  }
  
  .article-footer p {
    font-size: 0.875rem;
    color: #666;
  }
  
  a {
    color: #FF0080;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #D10069;
  }
  
  @media (min-width: 768px) {
    .rescue-article {
      padding: 2rem;
    }
  
    .article-title {
      font-size: 3rem;
    }
  
    .header-image {
      height: 24rem;
    }
  
    .section-title {
      font-size: 2.25rem;
    }
  
    .section-image {
      height: 16rem;
    }
  }