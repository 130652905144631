/* General styles */
#meet-us-1021 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.about-us-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4rem;
}

.about-us-content {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  margin-right: 2rem;
}

.about-us-graphic {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.about-us-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.about-us-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 1rem;
}

/* Fahida card styles */
.fahida-card, .john-card {
  flex: 0 1 300px;
  background-color: #f8f8f8;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.fahida-card img, .john-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.fahida-info, .john-info {
  padding: 1rem;
  text-align: center;
}

.fahida-name, .john-name {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.fahida-job, .john-job {
  display: block;
  font-size: 1rem;
  color: #666;
}

/* Banner styles */
.banner-712 {
  position: relative;
  text-align: center;
  color: white;
}

.cs-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
}

.cs-int-title {
  font-size: 3rem;
  font-weight: bold;
}

/* Who We Are section */
#content-page-852 {
  background-color: #f9f9f9;
  padding: 2rem 0;
}

.cs-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.cs-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.cs-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 1rem;
}

.cs-content ul {
  list-style: none;
  padding: 0;
}

.cs-content ul li {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 0.5rem;
}

.cs-content ul li strong {
  color: #333;
}

/* New styles for content with images */
.content-with-images {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.text-content {
  flex: 1;
  padding-right: 2rem;
}

.image-container {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
}

.image-container img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .about-us-container {
    flex-direction: column;
  }

  .about-us-content {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .fahida-card, .john-card {
    flex: 0 1 100%;
  }

  .about-us-title {
    font-size: 2rem;
  }

  .content-with-images {
    flex-direction: column;
  }

  .text-content {
    padding-right: 0;
    margin-bottom: 1rem;
  }

  .image-container {
    flex: 0 0 100%;
  }
}

@media (max-width: 480px) {
  #meet-us-1021 {
    padding: 1rem;
  }

  .about-us-title {
    font-size: 1.8rem;
  }

  .about-us-text {
    font-size: 0.9rem;
  }
}

.meet-our-team {
  color: #fff;
  text-align: center;
  margin: 20px 0;
  font-size: 2.5rem;
}

.underline {
  list-style: none;
  padding: 0;
  margin-top: -2%;
  margin-bottom: 4%;
  display: flex;
  justify-content: center;
}

.underline li {
  width: 10%;
  height: 3px;
  background-color: #FF0080;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .meet-our-team {
    font-size: 2.5rem;
    margin: 18px 0;
  }
}

@media (max-width: 992px) {
  .meet-our-team {
    font-size: 2rem;
    margin: 16px 0;
  }
}

@media (max-width: 768px) {
  .meet-our-team {
    font-size: 1.75rem;
    margin: 14px 0;
  }
}

@media (max-width: 576px) {
  .meet-our-team {
    font-size: 1.5rem;
    margin: 12px 0;
  }
}