.tag-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
  }
  .filter-tags{
    margin-top: 300%;
  }
  .tag-button {
    padding: 10px 15px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .tag-button.active {
    background-color: #FF0080; /* Active color */
    color: #fff;
  }
  
  /*-- -------------------------- -->
<---            Blog            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #blog-1540 {
    padding: var(--sectionPadding);
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-top: 13%;
  }
  #blog-1540:before {
    /* backgorund color */
    content: '';
    width: 100%;
    height: 100%;
    background: var(--primary);
    opacity: .05;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: -1;
  }
  #blog-1540 .cs-container {
    width: 100%;
    /* changes to 1280px at large desktop */
    max-width: 36.5rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 48px - 64px */
    gap: clamp(3rem, 6vw, 4rem);
    position: relative;
  }
  #blog-1540 .cs-content {
    /* set text align to left if content needs to be left aligned */
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* centers content horizontally, set to flex-start to left align */
    align-items: center;
  }
  #blog-1540 .cs-title {
    /* max width of 18 characters, incldues spaces between words */
    max-width: 23ch;
  }
  #blog-1540 .cs-card-group {
    width: 100%;
    margin: 0;
    padding: 0;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(12, 1fr);
    gap: 1.25rem;
  }
  #blog-1540 .cs-item {
    text-align: left;
    list-style: none;
    /* 16px - 24px */
    padding: clamp(1rem, 3vw, 1.5rem);
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 2.5rem;
    grid-column: span 12;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: border-color 0.3s;
  }
  #blog-1540 .cs-item:hover {
    border-color: #FF0080;
  }
  #blog-1540 .cs-link {
    text-decoration: none;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }
  #blog-1540 .cs-picture-group {
    width: 100%;
    margin-bottom: 1.5rem;
    position: relative;
  }
  #blog-1540 .cs-picture {
    width: 100%;
    /* 200px - 340px, resets to 200px at desktop */
    height: clamp(12.5rem, 45vw, 21.25rem);
    background-color: #1a1a1a;
    display: block;
    position: relative;
    z-index: 1;
    overflow: hidden;
    /* prevents flexbox from squsihing it */
    flex: none;
  }
  #blog-1540 .cs-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.6s, opacity 0.3s;
  }
  #blog-1540 .cs-mask {
    --maskBG: #fff;
    --maskBorder: #e8e8e8;
    width: 101%;
    height: 101%;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
  }
  #blog-1540 .cs-flex {
    margin: 0 0 1.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
  #blog-1540 .cs-tag {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2em;
    text-align: center;
    width: fit-content;
    margin-right: 0;
    padding: 0.5rem 1rem;
    color: #FF0080;
    border-radius: 6.25rem;
    display: block;
    position: relative;
    overflow: hidden;
  }
  #blog-1540 .cs-tag::before {
    content: "";
    width: 100%;
    height: 100%;
    background: #FF0080;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
  }
  #blog-1540 .cs-date {
    font-size: 1rem;
    line-height: 1.5em;
    margin: 0;
    color: var(--bodyTextColor);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }
  #blog-1540 .cs-item-text {
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 400;
    margin: 0;
    color: var(--bodyTextColor);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }
  #blog-1540 .cs-h3 {
    /* 20px - 25px */
    font-size: clamp(1.25rem, 2vw, 1.5625rem);
    font-weight: 700;
    line-height: 1.2em;
    text-align: inherit;
    margin: 0 0 0.5rem 0;
    color: #FF0080;
    transition: color 0.3s;
  }
  #blog-1540 .cs-bottom {
    margin: 1.5rem 0 0 0;
    padding: 1.5rem 0 0 0;
    border-top: 1px solid #E8E8E8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
  }
  #blog-1540 .cs-author-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }
  #blog-1540 .cs-profile {
    width: 3.125rem;
    height: 3.125rem;
    border: 2px solid #BABABA;
    background-color: #BABABA;
    border-radius: 50%;
    /* clips image corners to make circle */
    overflow: hidden;
    position: relative;
    display: block;
  }
  #blog-1540 .cs-profile img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* makes the image behave like a background image */
    object-fit: cover;
  }
  #blog-1540 .cs-name {
    font-size: 1rem;
    line-height: 1.2em;
    font-weight: 700;
    margin: 0;
    color: #FF0080;
    display: block;
  }
  #blog-1540 .cs-job {
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 700;
    margin: 0;
    color: #FF0080;
    display: block;
  }
  #blog-1540 .cs-wrapper {
    width: 3rem;
    height: 3rem;
    border: 1px solid #bababa;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #blog-1540 .cs-background {
    --backgroundBG: #fff;
    width: 100%;
    min-width: 120rem;
    height: auto;
    position: absolute;
    left: 0;
  }
  #blog-1540 .cs-background-top {
    top: 0;
  }
  #blog-1540 .cs-background-bottom {
    bottom: 0;
    transform: rotateX(180deg);
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 64rem) {
  #blog-1540 .cs-container {
    max-width: 80rem;
  }
  #blog-1540 .cs-picture {
    height: 12.5rem;
  }
  #blog-1540 .cs-item {
    grid-column: span 4;
  }
}

@media (max-width: 767px) {
  .cs-content {
    padding: 1.5rem 0.5rem;
  }

  .cs-topper {
    font-size: 0.675rem;
  }

  .cs-title {
    font-size: 1.5rem;
  }

  .cs-text {
    font-size: 0.575rem;
  }

  .tag-button{
    font-size: 12px;

  }
}

/* Media query for screens smaller than 480px */
@media (max-width: 479px) {
  #blog-1540{
padding-top: 25%;
  }
  .tag-button{
    font-size: 12px;

  }
  .cs-content {
    padding: 1rem 0.25rem;
  }

  .cs-topper {
    font-size: 0.55rem;
  }

  .cs-title {
    font-size: 1.2rem;
  }

  .cs-text {
    font-size: 0.25rem;
  }
}
                                