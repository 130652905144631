/* ProfessionalsFilter.css */

  
  .filter-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .filter-group {
    flex: 1 1 200px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  .location-select, .search-location-button {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .location-select {
    background-color: #fff;
  }
  
  .search-location-button {
    margin-top: 2%;
    background-color: #FF0080;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-location-button:hover {
    background-color: #FF0089;
  }
  
  .professionals-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  

  .location-filter{
    padding-top: 15%;
    padding-left:10% ;
    padding-right:10% ;
    padding-bottom:10% ;

  }

  .no-matches {
    color: #d9534f;
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 20px;
  }
  .professional-card {
  
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
    color: inherit;
  }
  
  .professional-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  }
  
  .card-content {
    padding: 20px;
  }
  
  .professional-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .professional-info {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
  }
  
  .no-results {
    text-align: center;
    color: #666;
    font-style: italic;
  }
  
  @media (max-width: 600px) {
    .filter-controls {
      flex-direction: column;
    }
    
    .filter-group {
      flex: 1 1 100%;
    }
  }