/* Spay & Neuter Programs Stylesheet */

.spay-neuter-programs {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .banner-712 {
    position: relative;
    height: 400px;
    overflow: hidden;
  }
  
  .banner-712 .cs-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  .banner-712 .cs-int-title {
    font-size: 3rem;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .banner-712 .cs-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  h2 {
    color: #FF0080;
    border-bottom: 2px solid #FF0080;
    padding-bottom: 10px;
  }
  
  section {
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .benefits ul, .get-involved ul {
    background-color: #E8C5E5;
    padding: 20px 20px 20px 40px;
    border-radius: 8px;
  }
  
  .how-it-works ol {
    background-color: #FF0080;

    counter-reset: item;
    list-style-type: none;
    padding-left: 0;
  }
  
  .how-it-works li {
    counter-increment: item;
    margin-bottom: 15px;
    padding-left: 50px;
    position: relative;
  }
  
  .how-it-works li::before {
    content: counter(item);
    background-color: #FF0080;
    color: #ffffff;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .success-stories ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .success-stories li {
    margin-bottom: 15px;
    padding: 15px;
    background-color: #E8C5E5;
    border-radius: 8px;
  }
  
  .footer {
    background-color: #FF0080;
    color: #ffffff;
    text-align: center;
    padding: 20px;
    margin-top: 30px;
    border-radius: 8px;
  }
  
  .footer a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .banner-712 {
      height: 300px;
    }
  
    .banner-712 .cs-int-title {
      font-size: 2rem;
    }
  
    .spay-neuter-programs {
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .banner-712 {
      height: 200px;
    }
  
    .banner-712 .cs-int-title {
      font-size: 1.5rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    .how-it-works li {
      padding-left: 40px;
    }
  }