.cs-picture {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
  }
  
  .cs-picture img {
    max-width: 80%;
    height: auto;
  }

  .site-wrap {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .site-wrap p {
    margin-bottom: 1.5rem;
  }