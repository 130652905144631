/*-- -------------------------- -->
<---          Banner            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #banner-712 {
        /* 175px - 200px top */
        padding: clamp(10.9375rem, 10vw, 12.5rem) 1rem 6.25rem;
        position: relative;
        z-index: 1;
    }
    #banner-712 .cs-container {
        text-align: center;
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
    }
    #banner-712 .cs-int-title {
        /* 39px - 61px */
        font-size: clamp(2.4375rem, 6.4vw, 3.8125rem);
        font-weight: 900;
        line-height: 1.2em;
        text-align: inherit;
        margin: 0;
        color: var(--bodyTextColorWhite);
        position: relative;
    }
    #banner-712 .cs-background {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    #banner-712 .cs-background:before {
        /* background color overlay */
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        background: #000;
        opacity: 0.75;
        top: 0;
        left: 0;
        z-index: 1;
    }
    #banner-712 .cs-background img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        /* Makes img tag act as a background image */
        object-fit: cover;
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    #banner-712 .cs-background:before {
        opacity: 1;
        background: linear-gradient(
            90.01deg,
            rgba(0, 0, 0, 0.9) 16.86%,
            rgba(0, 0, 0, 0) 100%
        );
    }
}

                                
/*-- -------------------------- -->
<---        Content Page        -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #content-page-852 {
        padding: var(--sectionPadding);
        background-color: #fff;
        /* clips the wave background from causing overflow issues when it goes off screen */
        overflow: hidden;
        position: relative;
        z-index: 1;
    }
    #content-page-852 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
        position: relative;
    }
    #content-page-852 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: left;
        width: 100%;
        max-width: 46.125rem;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: flex-start;
    }
    #content-page-852 .cs-title {
        font-size: var(--headerFontSize);
        font-weight: 900;
        line-height: 1.2em;
        text-align: inherit;
        width: 100%;
        max-width: 100%;
        margin: 0 0 1rem 0;
        color: var(--headerColor);
        position: relative;
    }
    #content-page-852 h2,
    #content-page-852 h3,
    #content-page-852 h4,
    #content-page-852 h5,
    #content-page-852 h6 {
        font-weight: 700;
        text-align: inherit;
        margin: 0 0 1rem 0;
        color: var(--headerColor);
    }
    #content-page-852 h2 {
        font-size: 2rem;
        margin-top: 2rem;
    }
    #content-page-852 h3 {
        font-size: 1.5rem;
        color: #FF0080;
    }
    #content-page-852 h4,
    #content-page-852 h5,
    #content-page-852 h6 {
        font-size: 1.25rem;
    }
    #content-page-852 .cs-button-solid {
        margin-bottom: 2rem;
    }
    #content-page-852 .cs-no-margin {
        margin: 0;
    }
    #content-page-852 .cs-color {
        color: #FF0080;
    }
    #content-page-852 p {
        font-size: var(--bodyFontSize);
        line-height: 1.5em;
        text-align: inherit;
        width: 100%;
        margin: 0 0 1rem 0;
        color: var(--bodyTextColor);
    }
    #content-page-852 p:last-of-type {
        margin-bottom: 2rem;
    }
    #content-page-852 p a {
        font-size: inherit;
        line-height: inherit;
        text-decoration: underline;
        color: #FF0080;
    }
    #content-page-852 ol,
    #content-page-852 ul {
        padding-left: 1.5rem;
        margin: 0 0 2rem 0;
        color: var(--bodyTextColor);
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    #content-page-852 ul li {
        list-style: none;
        color: inherit;
        position: relative;
    }
    #content-page-852 ul li:before {
        /* custom list bullet */
        content: "";
        width: 3px;
        height: 3px;
        background: currentColor;
        opacity: 1;
        border-radius: 50%;
        position: absolute;
        display: block;
        top: 0.625rem;
        left: -0.75rem;
    }
    #content-page-852 img {
        width: 100%;
        height: auto;
        display: block;
    }
    #content-page-852 .cs-image-group {
        width: 50%;
        max-width: 27.0625rem;
        display: none;
        flex-direction: column;
        gap: 1.25rem;
        position: relative;
    }
    #content-page-852 .cs-image-group:after {
        /* colored box */
        content: "";
        width: 100%;
        /* 177px - 258px */
        height: clamp(11.0625rem, 19vw, 16.125rem);
        background: #FF0080;
        border-radius: 5.25rem 0 8.25rem;
        opacity: 0.15;
        display: block;
    }
    #content-page-852 .cs-picture {
        width: 100%;
        /* 300px - 520px */
        height: clamp(18.75rem, 40vw, 32.5rem);
        box-shadow: 0px 3.3478px 50.2169px rgba(0, 0, 0, 0.16);
        /* 125px - 200px */
        border-radius: 0 clamp(7.8125rem, 15vw, 12.5rem) 0
            clamp(7.8125rem, 15vw, 12.5rem);
        /* prevents border from affecting height and width */
        box-sizing: border-box;
        /* clips img tag corners */
        overflow: hidden;
        display: block;
        position: relative;
    }
    #content-page-852 .cs-picture img {
        width: 100%;
        height: 100%;
        /* makes it act like a background image */
        object-fit: cover;
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    #content-page-852 .cs-container {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    #content-page-852 .cs-content {
        flex: none;
        width: 60%;
        /* sens it to the right in the 2nd position */
        order: 2;
    }
    #content-page-852 .cs-image-group {
        display: flex;
    }
}
.content-with-images {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 40px; /* Add space between columns */

  }
  
  .text-content {
    flex: 1;
  }
  
 
.image-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 40%;
    position: relative;
  }
  
  .image-container img {
    max-width: 100%;
    border: 10px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .image-container img:first-child {
    margin-bottom: -60px; /* Overlap the second image */
  }
  
  .image-container img:last-child {
    transform: rotate(5deg); /* Rotate the second image */
    position: relative;
    z-index: -1; /* Position behind the first image */
  }
  
  .fancy-frame {
    position: relative;
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .fancy-frame::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 10px solid #fff;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 768px) {
    .content-with-images {
      flex-direction: column;
      gap: 20px;
    }
  
    .text-content {
      margin-bottom: 0;
    }
  
    .image-container {
      width: 100%;
      align-items: center;
    }
  
    .image-container img:first-child {
      margin-bottom: 0;
    }
  
    .image-container img:last-child {
      transform: none;
      position: static;
      z-index: auto;
    }
  }

  