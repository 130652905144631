/* Indigenous Dog Programs Stylesheet */

.indigenous-dog-programs {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .banner-712 {
    position: relative;
    height: 400px;
    overflow: hidden;
  }
  
  .banner-712 .cs-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  .banner-712 .cs-int-title {
    font-size: 3rem;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .banner-712 .cs-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  h2 {
    color: #FF0080;
    border-bottom: 2px solid #FF0080;
    padding-bottom: 10px;
  }
  
  section {
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  ul, ol {
    padding-left: 20px;
  }
  
  .footer {
    background-color: #FF0080;
    color: #ffffff;
    text-align: center;
    padding: 20px;
    margin-top: 30px;
    border-radius: 8px;
  }
  
  .footer a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .banner-712 {
      height: 300px;
    }
  
    .banner-712 .cs-int-title {
      font-size: 2rem;
    }
  
    .indigenous-dog-programs {
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .banner-712 {
      height: 200px;
    }
  
    .banner-712 .cs-int-title {
      font-size: 1.5rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  }