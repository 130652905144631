.stats-card-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .stats-card {
    width: 800px;
    height: 450px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    background-color: #fff;
  }
  
  .stats-card-heading {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .stats-card-content {
    display: flex;
    justify-content: space-between;
  }
  
  .stats-card-item {
    text-align: center;
  }
  
  .stats-card-item-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .stats-card-item-value {
    font-size: 24px;
    font-weight: bold;
  }