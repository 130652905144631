/* General Styles for the Animal Details Section */
.animal-details {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  /* Dropzone Styles */
  .dropzone {
    padding: 20px;
    border: 2px dashed #007bff;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    text-align: center;
    transition: background-color 0.3s;
  }
  
  .dropzone.active {
    background-color: #e9f5ff;
  }
  
  .dropzone p {
    margin: 0;
    font-size: 16px;
    color: #666;
  }
  
  /* Image Upload Button Style */
  .upload-btn {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-btn:hover {
    background-color: #0056b3;
  }
  
  /* Styles for Uploaded Images */
  .uploaded-images-container {
    margin-top: 20px;
  }
  
  .uploaded-image {
    max-width: 200px;
    max-height: 200px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  /* Professional Picture Styles */
  .professional-pictures ul {
    list-style: none;
    padding: 0;
  }
  
  .professional-pictures img {
    max-width: 100px;
    max-height: 100px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  /* Form Styles */
  .form-field {
    margin-bottom: 200px;
  }
  
  .form-field-container {
    margin-bottom: 16px; /* Adjust the value as needed */
  }

  .grid-container {
    display: flex; /* Uses flexbox to line up children in a row */
    justify-content: space-around; /* Distributes space evenly around items */
    padding: 0; /* Removes padding */
    list-style: none; /* Removes default list styling */
  }
  
  .grid-container li {
    flex: 1; /* Each list item will take equal width */
    margin: 5px; /* Optional: Adds some space between the images */
  }
  
  .grid-container img {
    width: 100%; /* Makes images fill their container */
    height: auto; /* Keeps image aspect ratio intact */
    max-height: 200px; /* Limits image height, adjust as needed */
  }
  