.rescue-article{
  padding-top: 13%;
}
.section-image {
  max-width: 100%;  /* Makes the image responsive */
  height: auto;    /* Maintains the aspect ratio */
  display: block;  /* Removes any inline-block spacing */
  margin: 0 auto;  /* Centers the image */
}
.article {
    max-width: 56rem;
    margin: 0 auto;
    padding: 2rem 1rem;
    background-color: white;
    color: #333;
  }
  
  .header {
    margin-bottom: 2rem;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .authorInfo {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .authorImage {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
  
  .authorName {
    font-weight: 600;
  }
  
  .authorRole {
    font-size: 0.875rem;
    color: #666;
  }
  
  .headerImage {
    width: 100%;
    height: 16rem;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .section {
    margin-bottom: 2rem;
  }
  
  .sectionTitle {
    font-size: 1.875rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .paragraph {
    margin-bottom: 1rem;
    line-height: 1.625;
    color: #444;
  }
  
  .sectionImage {
    width: 100%;
    height: 12rem;
    object-fit: cover;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .alert {
    margin-bottom: 2rem;
    border-left: 4px solid #FF0080;
  }
  
  .footer {
    border-top: 1px solid #e5e5e5;
    padding-top: 1rem;
  }
  
  .footerText {
    font-size: 0.875rem;
    color: #666;
  }
  
  a {
    color: #FF0080;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #D10069;
  }
  
  @media (min-width: 768px) {
    .article {
      padding: 2rem;
    }
  
    .title {
      font-size: 3rem;
    }
  
    .headerImage {
      height: 24rem;
    }
  
    .sectionTitle {
      font-size: 2.25rem;
    }
  
    .sectionImage {
      height: 16rem;
    }
  }