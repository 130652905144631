.professional-profile {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 15%;
}

.name {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.profile-images-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}
.professional-profile.skeleton {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}
.profile-image {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropzone-container {
  margin-bottom: 20px;
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.upload-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.profession {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
}

.card-container {
  margin-bottom: 30px;
  width: 100%; /* Changed to 100% to take full width of its parent */
  display: flex;
  justify-content: center; /* Center the card horizontally */
}

.service-form {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-form > div {
  margin-bottom: 20px;
}

.service-form label {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}
.service-card {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  width: 80%; /* This will now be 80% of the card-container's width */
  max-width: 800px; /* Added max-width to prevent it from becoming too wide on large screens */
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
}

.profession-service-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.profession {
  font-size: 1.2em;
  color: #666;
  margin: 0; /* Remove default margin */
  flex-grow: 1; /* Allows profession to take up available space */
}

.service-button {
  background-color: #2196F3;
  color: white;
  white-space: nowrap;
  margin-left: 20px; /* Adds some space between profession and button */
}

.service-button:hover {
  background-color: #1976D2;
}

/* Responsive adjustment */
@media (max-width: 600px) {
  .profession-service-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .service-button {
    margin-left: 0;
    margin-top: 10px;
  }
}

.service-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.service-details-input {
  width: 100%;
}

.service-submit-button {
  align-self: flex-start;
}

.delete-ticket-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 4px;
}

.details-heading {
  font-size: 1.8em;
  color: #333;
  margin-top: 30px;
  margin-bottom: 20px;
}
.details-content {
  white-space: pre-wrap;
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.professional-details-container {
  margin-top: 20px;
}

.add-pet-link {
  color: #1976d2;
  text-decoration: none;
}

.add-pet-link:hover {
  text-decoration: underline;
}